<script>
import {listCentroCusto as list, deleteCentroCusto} from '@/domain/financeiro/services/centroCusto'
import {UTable, UTh, UTr, UTd, UCheckbox, date, LocalStorage} from 'uloc-vue'
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import MenuOptions from '../../layout/context-menu/context-window-options'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import listOptions from '../windows/listOptions'
import windowCentroCusto from '../windows/cc'
import {datePtToEn} from '@/utils/date'
import tableColumnsCache from "@/utils/tableColumnsCache"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import DateInput from "@/reuse/input/Date"
import TaskListMenu from "@/components/sltarefas/components/lista/list-menu"
import ContextMenuTaskItem from "@/components/sltarefas/components/lista/context-menu"

const listStorageName = 'sl.fin-cc.lista'
const listStorage = tableColumnsCache(listStorageName, [
  {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
  {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 1},
  {label: 'Descrição', name: 'descricao', active: true, sortable: true, ordering: 1},
  {label: 'Meta Receita', name: 'metaReceita', active: true, sortable: true, ordering: 1},
  {label: 'Meta Despesa', name: 'metaDespesa', active: true, sortable: true, ordering: 1},
  {label: 'Restrito', name: 'restricao', active: false, sortable: true, ordering: 1}, // @TODO
  {label: 'Saldo', name: 'saldo', active: false, sortable: true, ordering: 1}, // @TODO
  {label: 'Situação', name: 'status', active: true, sortable: true, ordering: 1},
], 1)

let filters = {
  id: null,
  search: null,
  tipo: null,
  data1: null,
  data2: null,
  status: 1,
  active: true
}

export default {
  name: 'CentroCusto',
  provide: function () {
    return {
      container: this
    }
  },
  props: {
  },
  mixins: [DefaultMixin],
  components: {
    ContextMenuTaskItem,
    TaskListMenu,
    DateInput,
    ECol,
    ERow,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    // ErpSelect,
    ErpSField,
    // ErpSelect,
    // UPopover,
    // UTooltip
  },
  data() {
    const filtros = JSON.parse(JSON.stringify(filters))
    return {
      listStorage: listStorage,
      listType: 1,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: filtros,
        selected: [],
        loading: false
      },
      stats: null,
      counts: null
    }
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  beforeMount () {
    if (this.$route.hash) {
      this.parseHash()
    }
  },
  watch: {
    '$route' () {
      this.$nextTick(() => {
        /*if (this.parseHash()) {
          this.load()
        }*/
      })
    }
  },
  mounted() {
    this.load()
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.tipo && extraFilters.push(`&tipo=${this.table.filters.tipo}`)
      extraFilters.push(`&status=${this.table.filters.status ? 1 : 0}`)
      this.table.filters.search && extraFilters.push(`&search=${this.table.filters.search}`)

      /*if (Array.isArray(this.table.filters.status) && this.table.filters.status.length > 0) {
        extraFilters.push(`&status=${this.table.filters.status.join(',')}`)
      }*/

      this.table.filters.pessoa && extraFilters.push(`&pessoa=${this.table.filters.pessoa.id || this.table.filters.pessoa}`)

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.stats = data.stats
            if (data.counts) {
              this.counts = data.counts
            }

            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    clearFilters() {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo(t = null) {
      console.log('New...')
      this.windowCentroCusto(null)
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.descricao || this.table.busca
      })
    },
    abrir(id) {
      // this.$router.push({name: 'sl.tarefas.show', params: {id: id}})
      this.windowCentroCusto(id, this.listType)
    },
    edit(id) {
      this.windowCentroCusto(id, this.listType)
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este registro? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir centro-custo-' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteCentroCusto(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Centro de Custo excluído com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(listStorageName, newConfig)
      this.listStorage = newConfig
    },
    changeListType (v) {
      this.listType = v
      this.table.filters.status = v
      this.load()
    },
    windowCentroCusto: windowCentroCusto,
    convert (d) {
      return datePtToEn(d)
    }
  },
  meta: {
    title: 'Centro de Custo',
    name: 'CentroCusto'
  }
}
</script>

<template>
    <div class="fin-container wrapper-md">
      <e-row class="full-width m-b-lg">
        <e-col style="max-width: 350px">
        </e-col>
        <e-col class="justify-end flex">
          <u-btn @click="novo" no-caps color="green" class="app-btn font-12 no-border-radius-right">
            <span>+ Novo Centro de Custo</span> <span class="font-10 m-l m-r-n-xs">F2</span>
          </u-btn>
        </e-col>
      </e-row>
      <div class="erp-list list-tasks no-pad">
        <div class="erp-list-top-btns items-end">
          <div class="actives-btns col-grow-1">
            <u-btn @click="changeListType(1)" label="Ativo" class="active-table-btn" :class="{active: listType === 1}" no-caps />
            <u-btn @click="changeListType(0)" label="Inativo" class="active-table-btn" :class="{active: listType === 0}" no-caps />
          </div>
          <div class="end font-14 bg-white flex-zero flex no-wrap">
              <e-col style="min-width: 170px" class="m-l m-r">
                <erp-s-field
                    view="ll"
                    label="Buscar"
                >
                  <erp-input shortkey="F3" v-model="table.filters.search" @keydown.enter="pesquisar"/>
                </erp-s-field>
              </e-col>
              <e-col style="min-width: 150px" class="m-r">
                <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (De)" v-model="table.filters.data1"/>
              </e-col>
              <e-col style="min-width: 150px" class="m-r">
                <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (Até)" v-model="table.filters.data2"/>
              </e-col>
<!--              <e-col style="min-width: 150px">
                <erp-s-field
                    view="ll"
                    label="Filtro Data"
                >
                  <erp-select
                      :options="[
                          {label: 'Criação', value: 'createdAt'},
                          {label: 'Início', value: 'startTime'},
                          {label: 'Prazo', value: 'deadline'},
                      ]"
                      v-model="table.filters.tipoData" />
                </erp-s-field>
              </e-col>
              <e-col style="min-width: 150px">
                <status-task-select field-view="ll" multiple v-model="table.filters.status" @change="load()" code-as-value />
              </e-col>-->

          </div>
        </div>
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table erp-table-options table-fin"
            :rows-per-page-options="[20, 50, 100]"
            :hide-no-results-label="true"
        >
          <template slot="top" slot-scope="props">

          </template>
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th v-for="col in props.cols" :key="col.name" :props="props">
               {{ col.label }}
              <div v-if="col.name === 'options'" class="text-center">
                <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
              </div>
            </u-th>
          </u-tr>

          <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props" :class="{'u-table-item-destac': props.row.isToday}">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs"/>
            </u-td>
            <u-td style="max-width: 40px; min-width: 40px; width: 40px" key="id" :props="props">
              {{ props.row.id }}
            </u-td>
            <u-td class="" key="nome"
                  :props="props">
              {{ props.row.nome }}
            </u-td>
            <u-td :title="props.row.descricao" class="td-limit" key="descricao"
                  :props="props">
              {{ props.row.descricao }}
            </u-td>
            <u-td key="metaReceita"
                  :props="props">
              R$ {{ props.row.metaReceita|moeda }}
            </u-td>
            <u-td key="metaDespesa"
                  :props="props">
              R$ {{ props.row.metaDespesa|moeda }}
            </u-td>
            <u-td key="restricao"
                  :props="props">
              -
            </u-td>
            <u-td key="saldo"
                  :props="props">
              -
            </u-td>
            <u-td key="status"
                  :props="props">
              <span class="lbl-status positive" v-if="props.row.status === 1">Ativo</span>
              <span class="lbl-status negative" v-if="props.row.status === 0">Inativo</span>
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <task-list-menu @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                                     @excluir="excluir(props.row.id)" :leilao="props.row"/>
                </menu-options>
              </e-btn-table-options>
            </u-td>
            <context-menu-task-item @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                                      @excluir="excluir(props.row.id)" :leilao="props.row"/>
          </u-tr>
        </u-table>
      </div>
    </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
