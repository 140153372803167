export default function (id) {
  let window = typeof id !== 'undefined' ? `cc.${id}.show` : 'cc.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Centro de Custo',
    move: false,
    backdrop: true,
    clickOutside: false,
    closeOnEsc: false,
    windowClass: 'erp-window-modern v2 no-border-radius',
    width: '1000',
    height: '680',
    props: {
      id: id,
      $router: this.$router,
    }
  }, () => import('../components/CentroCustoWindow'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        update: (wid, val) => {
          this.load && this.load()
        },
        created: (wid, val) => {
          this.load && this.load()
        }
      })
    }) // return wid
}
